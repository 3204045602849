/* 
  就是把不同模块的 state attion。。。。放到一个对象里面去
*/
import { userinfo } from "@/api/app"
/*
 * @Descripttion:
 * @version:
 * @Author:
 * @Date: 2022-03-06 11:23:28
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-03-14 13:50:38
 */
const state = {
  userinfo:{},//用户数据
}

// getters计算属性
const getters = {
  // getDemoValue: (state) => state.cityCode,
}

// actions异步请求处理
const actions = {
  async fetchUserinfo() {
    let { data } = await userinfo()
    // console.log("otherVal", data.data)
    state.userinfo = data.data.info
  },
}

// mutations主要用来操作state
const mutations = {
  // setNavList (state, navList) {
  //   state.navList = navList;
  //   // console.log('setNavList', state.navList);
  // },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
