import Vue from 'vue'
import VueRouter from 'vue-router'
// 把这段代码直接粘贴到router/index.js中的Vue.use(VueRouter)之前
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
  return originalPush.call(this, location).catch(err => { })
};
Vue.use(VueRouter); //使用插件

export const routes = [
  { //一级路由
    path: '/', // 根路由 
    name: 'index',
    component: () => import('../views/Index.vue'),
    meta: {//初始化的meta
      isShow: true,
      title: '',
      content: {
        title: '',
        keywords: '',
        description: '',
      }
    }
    // redirect: "/index", //重定向
  },
  {
    path: '/shoppingMall',
    name: 'shoppingMall',
    component: () => import('../views/shoppingMall.vue'),
  },
  {
    path: '/commodityDetails',
    name: 'commodityDetails',
    component: () => import('../views/commodityDetails.vue'),
  },
  {
    path: '/shoppingTrolley',
    name: 'shoppingTrolley',
    component: () => import('../views/shoppingTrolley.vue'),
  },
  {
    path: '/BuyItNow',
    name: 'BuyItNow',
    component: () => import('../views/BuyItNow.vue'),
  },
  {
    path: '/BuyItTogether',
    name: 'BuyItTogether',
    component: () => import('../views/BuyItTogether.vue'),
  },
  {
    path: '/newsCenter',
    name: 'newsCenter',
    component: () => import('../views/newsCenter.vue'),
  },
  {
    path: '/newsDetails',
    name: 'newsDetails',
    component: () => import('../views/newsDetails.vue'),
  },
  {
    path: '/Pay',
    name: 'Pay',
    component: () => import('../views/Pay.vue'),
  },
  {
    path: '/PayGood',
    name: 'PayGood',
    component: () => import('../views/PayGood.vue'),
  },
  {
    path: '/footerPage',
    name: 'footerPage',
    component: () => import('../views/footerPage.vue'),
  },
  {
    path: '/UserIndex',
    name: 'UserIndex',
    component: () => import('../views/UserIndex.vue'),
    children: [
      {//基础信息
        path: '/UserInfo',
        name: 'UserInfo',
        component: () => import('../components/UserMain/UserInfo.vue'),
      },
      {//升级会员
        path: '/UpLevel',
        name: 'UpLevel',
        component: () => import('../components/UserMain/UpLevel.vue'),
      },
      {//地址管理
        path: '/AdressInfo',
        name: 'AdressInfo',
        component: () => import('../components/UserMain/AdressInfo.vue'),
      },
      {//新增地址
        path: '/AddAdress',
        name: 'AddAdress',
        component: () => import('../components/UserMain/AddAdress.vue'),
      },
      {//修改地址
        path: '/EditAdress',
        name: 'EditAdress',
        component: () => import('../components/UserMain/EditAdress.vue'),
      },
      {//普通订单
        path: '/UserOrders',
        name: 'UserOrders',
        component: () => import('../components/UserMain/UserOrders.vue'),
      },
      {//订单详情
        path: '/OrderDetails',
        name: 'OrderDetails',
        component: () => import('../components/UserMain/OrderDetails.vue'),
      },
      {//取消订单
        path: '/ClearOrder',
        name: 'ClearOrder',
        component: () => import('../components/UserMain/ClearOrder.vue'),
      },
      {//查看物流
        path: '/LookLogistics',
        name: 'LookLogistics',
        component: () => import('../components/UserMain/LookLogistics.vue'),
      },
      {//申请退货退款
        path: '/RefundReturn',
        name: 'RefundReturn',
        component: () => import('../components/UserMain/RefundReturn.vue'),
      },
      {//填写快递信息
        path: '/WriteCourier',
        name: 'WriteCourier',
        component: () => import('../components/UserMain/WriteCourier.vue'),
      },
      {//已填写快递信息
        path: '/WriteOk',
        name: 'WriteOk',
        component: () => import('../components/UserMain/WriteOk.vue'),
      },
      {//申请成为供应商
        path: '/BeVendor',
        name: 'BeVendor',
        component: () => import('../components/UserMain/BeVendor.vue'),
      },
      {//支付供应商保证金
        path: '/BeVendorPay',
        name: 'BeVendorPay',
        component: () => import('../components/UserMain/BeVendorPay.vue'),
      },
      {//集采订单列表
        path: '/TogertherList',
        name: 'TogertherList',
        component: () => import('../components/UserMain/TogertherList.vue'),
      },
      {//集采订单
        path: '/TogertherOrders',
        name: 'TogertherOrders',
        component: () => import('../components/UserMain/TogertherOrders.vue'),
      },
      {//填写发票
        path: '/invoice',
        name: 'invoice',
        component: () => import('../components/UserMain/invoice.vue'),
      },
      ]
  },
  {
    path: '*',
    component: () => import('../views/NoFound.vue'),
  }
]

const router = new VueRouter({
  routes,
  mode: 'hash' //路由匹配模式  hash 
})
router.beforeEach((to, from, next) => {
  // 让页面回到顶部
  document.documentElement.scrollTop = 0;
  next();
});
/* 
  权限控制：把不需要登录的页面 称为 白名单 

*/

export default router
